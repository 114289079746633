/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <!-- <div id="progressBar">
    <div class="loader"></div>
      </div>-->
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-4 column">
              <div class="heading-profile">
                <h3>Hotel List</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                    <div class="d-flex">
                      <div>
                        <h6>
                          List of Hotels
                          <span class="tableTotal">(Total : {{ totalCount }})</span>
                        </h6>
                      </div>
                      <div class=" ml-3 ">
                        <b-button variant="success" @click="generateExcelForHotelViews()"
                          >Download</b-button
                        >
                      </div>
                      <div class="ml-auto">
                        <form class="search-form mr-form">
                          <input
                            type="text "
                            class="form-control"
                            v-model="searchText"
                            @input="viewHotelsViewsList(1)"
                            placeholder="Search Here..."
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>

                          <th>Name</th>
                          <th>Views</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in count" v-bind:key="item._id">
                          <td>{{ index + 1 + (page - 1) * 10 }}</td>

                          <td>
                            <div>{{ item.name }}</div>
                          </td>
                          <td>
                            <div>{{ item.length }}</div>
                          </td>
                          <!-- end v-repeat -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                    v-if="count.length === 0"
                  >
                    No Data Found
                  </div>
                  <!-- <div class="text-center" v-if="count.length >= 1">
                    <b-pagination
                      class="mb-0 float-right"
                      v-model="page"
                      align="center"
                      :total-rows="totalCount"
                      :per-page="formData.limit"
                      @input="viewHotelsViewsList(page)"
                    />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      currentPage: 1,
      searchText: "",
      count: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10
    };
  },
  created() {
    this.viewHotelsViewsList(this.page);
  },
  methods: {
    viewHotelsViewsList(pageNo) {
      this.count = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewHotelsViewsList(this.formData, data => {
        if (data.status === 200) {
          this.count = data.data.result;
          this.totalCount = data.data.count;
        } else {
        }
      });
    },

    generateExcelForHotelViews() {
      service.generateExcelForHotelViews({}, data => {
        service.downloadExcel(data, "hotelViews_List");
      });
    }
  }
};
</script>

<style></style>
